@import url('https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.slider-container {
  width: 100%; /* Full width for the slider */;
}
.leafletPopup {
  width: 450px !important;
}
.leafletPopup p {
  margin: 0 !important;
}

.slick-dots {
  bottom: -190px; /* Adjust vertical positioning of dots */
  display: flex !important;
  gap: 17px;
}

.slick-dots.slick-thumb {
  display: flex;
  justify-content: center;/* Center thumbnails horizontally */
}

.thumbnail {
  width: 200px; /* Set a specific width for thumbnails */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Rounded corners for a nicer look */
  cursor: pointer; /* Change cursor to pointer */
  overflow: hidden; /* Hide overflow if necessary */
}

.thumbnail:hover {
  opacity: 0.8; /* Slight effect on hover */
}

.slide-image {
  max-width: 100%; /* Ensure slide images are responsive */
  display: block; /* Prevent any inline spacing */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.footer {
  /* background-image: url('../public/footerBg.png') no-repeat center center;
  background-size: cover; */
  /* background-color: rgb(14, 10, 36); */
}

.App-link {
  color: #61dafb;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.leaflet-container {
  z-index: 1 !important;
}

.property-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 16px;
  transition: transform 0.2s;
}

.property-card:hover {
  transform: scale(1.05);
}

.property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-details {
  padding: 16px;
}

.property-title {
  font-size: 1.5em;
  margin: 0;
}

.property-location {
  color: #777;
  margin: 5px 0;
}

.property-price {
  font-size: 1.2em;
  color: green;
}

.property-description {
  margin: 10px 0;
}

.view-details {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.view-details:hover {
  background-color: #0056b3;
}
.responsive-iframe {
  width: 100%;
  height: 0;
  padding-bottom: 42.25%; /* 16:9 aspect ratio */
  position: relative;
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
}
 .branch-box:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  .branch-box-active {
    border: 2px solid #231c1b;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  

.rc-slider-track, .rc-slider-tracks {
    position: absolute;
    height: 8px;
    background-color: #78716c !important;
    border-radius: 6px;
}
.rc-slider-handle {
  background-color: #78716c !important;
  border-color: #78716c !important;
}
.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px rgba(120, 113, 108, 0.2) !important;
  border-color: #78716c !important;
}
.perspective {
  perspective: 1000px;
}

.backface-hidden {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.rotateY-180 {
  transform: rotateY(180deg);
}
.perspective {
  perspective: 1000px;
}

.flip-card {
  position: relative;
  width: 100%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flip-card-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
}

.flip-card-back {
  transform: rotateY(180deg);
}
.container{
  max-width: 1250px;
  margin: 0px auto;
  background-color: #fffcfc
}
.profile::-webkit-scrollbar{
    display: none;
}

.listing-sidebar_card{
      display: flex;
      border: 1px solid #ddd;
      border-radius: 5px;
      overflow: hidden;
}
.card_SlideBar_details{
  padding: 10px;
  margin: 0;
  font-size: 14px;

  h4{
    margin: 0 0 5px 0;
    font-size: 1.1em;
    font-weight: bold;
  }
  p{
    color: #464646;
  }
}
.card_SlideBar_details:hover{
  p{
    color: #C6A760;
  }
}
.ul{
  h2{
    font-weight: 700;
    font-family: Frank Ruhl Libre;
    font-size: 24px;
  }
}
.contact-form input,textarea{
  outline: none;
}
.UserDetails h2:hover{
  h2{
    color: #C6A760;
  }
}
.entry-title{
  font-size: 30px;
  display: flex;
  justify-content: flex-start;

}
.userModal {
  position: absolute;
  top: 160px; /* Start slightly higher for the up-to-down effect */
  right: 150px;
  width: 100px;
  /* padding: 20px; */
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: white;
  color: black;
  border-radius: 8px;
  opacity: 0;
  transform: translateY(-20px); 
  transition: opacity 0.3s ease, transform 0.3s ease; 
  z-index: -1; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.userModal.showModal {
  opacity: 1;
  transform: translateY(0); 
  z-index: 10; 
}

.userModal.hideModal {
  opacity: 0;
  transform: translateY(-20px); 
  z-index: -1;
}
::-WebKit-input-placeholder:after {
  content: "*";
  color: red;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.listing-results {
  margin-top: 1.5rem;
  background-color: white;
  height: 280px;
  overflow-y: auto;
  padding: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 537px;
  left: 514px;
  width: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-in-out, fadeIn 0.3s ease-in-out;
}

.listing-item {
  padding-top: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 0.5rem;
}

.listing-item:last-child {
  border-bottom: none;
}

.listing-content {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.icon {
  color: #464646;
}

.area-text {
  color: #464646;
  font-weight: 500;
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.faq-answer {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}
.faq-answer.open {
  max-height: 200px;
}

.single-content li,ul,p{
 
  font-size: 15px;
  font-weight: 400;
  
  line-height: 1.7;
  letter-spacing: 0.25px;
}
.single-content p,ul{
  margin-top: 0;
  margin-bottom: 1rem;
}
.single-content ul{
  list-style-type: disc; 
  margin-left: 5px; 
  padding-left: 10px;
}
.single-content ul ul{
  list-style-type: circle; /* Nested list uses hollow circles */
  margin-left: 5px;
  font-size: 15px;
  color: #464646;
}

.privacy_heading h1::after{
  content: '';
  height: 2px;
  width: 100px;
  background-color: rgb(1, 34, 1);
  display: flex;
  justify-content: center;
  
}
.single-content p{
  color: #464646;
  text-align: start;
  font-size: 15px;
}
.single-content ul li, ul ul li{
  font-size: 15px;

}
/* .footer-col {
  display: grid;
  grid-template-columns: 2fr 5fr;
} */

.paraghraph{
  font-size: 12px;
  color: #888;
}
@media print{
  .hide_on{
    display:none !important;
  }
  .imageGrid {
    display: grid;
    grid-template-columns: 1fr !important; /* Force a single column for printing */
  }

  /* Hide all images by default */
  .imageGrid img {
    display: none !important;
  }

  /* Show only the first image */
  .imageGrid img:nth-child(1) {
    display: block !important; /* Display the first image only */
  }

}
h1,h2,h3,h4,h5,h6,p,span,li{
  font-family: "Roboto","sans-serif";
}